import React from 'react';

interface PaginationProps {
  nbPages: number;
  page: number;
  onPageChange: (pageNumber: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ nbPages, page, onPageChange }) => {
  const renderPagination = () => {
    const pages: JSX.Element[] = [];
    if (nbPages <= 9) {
      for (let i = 1; i <= nbPages; i++) {
        pages.push(
          <button
            className="mx-1"
            key={i}
            onClick={() => onPageChange(i)}
            style={{ fontWeight: page === i ? 'bold' : 'normal' }}
          >
            {i}
          </button>
        );
      }
    } else {
      pages.push(
        <button className="mx-1" key={1} onClick={() => onPageChange(1)} style={{ fontWeight: page === 1 ? 'bold' : 'normal' }}>
          1
        </button>
      );
      if (page > 3) {
        pages.push(<span key="left-ellipsis">...</span>);
      }

      const startPage = Math.max(2, page - 1);
      const endPage = Math.min(nbPages - 1, page + 1);

      for (let i = startPage; i <= endPage; i++) {
        pages.push(
          <button
            className="mx-1"
            key={i}
            onClick={() => onPageChange(i)}
            style={{ fontWeight: page === i ? 'bold' : 'normal' }}
          >
            {i}
          </button>
        );
      }

      if (page < nbPages - 2) {
        pages.push(<span key="right-ellipsis">...</span>);
      }

      pages.push(
        <button className="mx-1" key={nbPages} onClick={() => onPageChange(nbPages)} style={{ fontWeight: page == nbPages ? 'bold' : 'normal' }}>
          {nbPages}
        </button>
      );
    }
    return pages;
  };

  return <div>{renderPagination()}</div>;
};

export default Pagination;