import React from 'react';
import Header from '../components/header'
import Footer from '../components/footer'
import SearchBar from '../components/searchbar'

function NotFound() {
  return (
    <div>
      <Header />
      <div className="container mx-auto mt-32 px-2">
        <div className="relative overflow-x-auto">
            <SearchBar />
            <div className="py-2 text-7xl">Page not found!</div>
          </div>
      </div>
      <Footer />
    </div>
  );
}

export default NotFound;