import { useParams } from 'react-router-dom';
import { TransfertData } from '../interfaces'
import { formatTimeAgo } from '../functions'
import { explorer } from '../constantes'
import Pagination from './pagination';

interface ListTransfersProps {
    transferts: TransfertData[];
    nbPages : number;
    error: string | null;
}

function ListTranferts({ transferts, nbPages, error }: ListTransfersProps) {
    const { address } = useParams<{ address: string }>();
    const { page } = useParams<{ page: string }>();
    const parsedPage = page ? parseInt(page) : 1;
    const currentPage = Number.isNaN(parsedPage) ? 1 : parsedPage;

    const handlePageChange = (pageNumber: number) => {
        let currentUrl = window.location.href;

        const regex = /\/\d+$/;
        if (regex.test(currentUrl)) {
            currentUrl = currentUrl.replace(regex, `/${pageNumber}`);
        } else {
            currentUrl = currentUrl + `/${pageNumber}`;
        }

        window.location.href = currentUrl;
    };

    return (
        <div>
            <div className="hidden lg:grid grid-cols-12 gap-4 rounded-md text-sm text-slate-700 bg-slate-200 text-center font-bold py-5">
                <div className="">Status</div>
                <div className="">Age</div>
                <div className="col-span-2">Operation Id</div>
                <div className="col-span-3">From</div>
                <div className="col-span-3">To</div>
                <div className="">Amount</div>
                <div className="">Token</div>
            </div>
            <div className="hidden lg:block text-center border border-slate-100 rounded-md mt-1 px-1">
                {error ?
                    <div className="text-left text-red-500 p-2">{error}</div>
                : null }
                {transferts.map((transfert) => (
                <div className="grid grid-cols-12 gap-4 py-2 rounded-md hover:bg-slate-100">
                    <div className="">
                        {transfert.STATUS === 1 ?
                            <span className="rounded-md bg-cyan-50 px-2 py-1 text-xs font-medium text-cyan-700 ring-1 ring-inset ring-cyan-600/20">Success</span>
                        : (transfert.STATUS === 2 ?
                            <span className="rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/20">Pending</span>
                        : 
                            <span className="rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20">Failed</span>
                        )}
                    </div>
                    <div className="truncate">{formatTimeAgo(transfert.TIMESTAMP)}</div>
                        <div className="col-span-2 truncate"><a href={explorer["operation"] + transfert.ID_OP}>{transfert.ID_OP}</a></div>
                        <div className="col-span-3 truncate">
                            {address !== transfert.FROM_ADDR?
                                <a href={explorer["address"] + transfert.FROM_ADDR}>{transfert.FROM_ADDR}</a>
                            :
                                <span className="italic">{transfert.FROM_ADDR}</span>
                            }
                        </div>
                        <div className="col-span-3 truncate">
                            {address !==  transfert.TO_ADDR?
                                <a href={explorer["address"] + transfert.TO_ADDR}>{transfert.TO_ADDR}</a>
                            :
                                <span className="italic">{transfert.TO_ADDR}</span>
                            }
                        </div>
                        {!transfert.SYMBOL ?
                            <div className="truncate col-span-2">NFT #{transfert.AMOUNT}</div>
                        :
                            <div className="col-span-2 grid grid-cols-2">
                                <div className="truncate">
                                    {parseInt(transfert.AMOUNT)/Math.pow(10, transfert.DECIMALS) >= 0.01 ? 
                                        (parseInt(transfert.AMOUNT)/Math.pow(10, transfert.DECIMALS)).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 })
                                    :
                                        <span className="text-sm">{"<"} 0.01</span>
                                    }
                                </div>
                                <div className="truncate"><a href={explorer["token"] + transfert.CONTRACT}>{transfert.SYMBOL}</a></div>
                            </div>
                        }
                    </div>
                ))}
            </div>
            <div className="lg:hidden">
                {error ?
                    <div className="text-left text-red-500 p-2">{error}</div>
                : null }
                {transferts.map((transfert) => (
                    <div className="text-center border border-slate-100 rounded-md mt-1 hover:bg-slate-50">
                        <div className="truncate bg-slate-100 rounded-md p-1"><a href={explorer["operation"] + transfert.ID_OP}>{transfert.ID_OP}</a></div>
                        <div className="grid grid-cols-5 px-1">
                            <div className="truncate col-span-2">
                                {address !== transfert.FROM_ADDR?
                                    <a href={explorer["address"] + transfert.FROM_ADDR}>{transfert.FROM_ADDR}</a>
                                :
                                    <span className="italic">{transfert.FROM_ADDR}</span>
                                }
                            </div>
                            {transfert.STATUS === 1 ?
                                <div className="animate-pulse text-cyan-600">
                                    <svg className="w-3 h-3 mx-auto mt-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                                    </svg>
                                </div>
                            : (transfert.STATUS === 2 ?
                                <div className="animate-pulse text-yellow-600">
                                    <svg className="w-3 h-3 mx-auto mt-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                                    </svg>
                                </div>
                            : <div className="animate-pulse text-red-600">
                                <svg className="w-3 h-3 mx-auto mt-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                                </svg>
                            </div>
                            )}
                            <div className="truncate col-span-2">
                                {address !== transfert.TO_ADDR?
                                    <a href={explorer["address"] + transfert.TO_ADDR}>{transfert.TO_ADDR}</a>
                                :
                                    <span className="italic">{transfert.TO_ADDR}</span>
                                }
                            </div>
                        </div>
                        <div className="grid grid-cols-2 px-1">
                            <div className="text-start">
                                {parseInt(transfert.AMOUNT)/Math.pow(10, transfert.DECIMALS) >= 0.01 ? 
                                    (parseInt(transfert.AMOUNT)/Math.pow(10, transfert.DECIMALS)).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 })
                                :
                                    <span className="text-sm">{"<"} 0.01</span>
                                } {transfert.SYMBOL}
                            </div>
                            <div className="text-end">{formatTimeAgo(transfert.TIMESTAMP)} ago</div>
                        </div>
                    </div>
                ))}
            </div>
            {nbPages?
                <div className="text-center mt-2">
                    <Pagination nbPages={nbPages} page={currentPage} onPageChange={handlePageChange} />
                </div>
            : null }
        </div>
    );
}
        
export default ListTranferts;