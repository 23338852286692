function Footer() {
    const copyToClipboard = (textToCopy: string) => {
        if (navigator.clipboard && navigator.clipboard.writeText) {
          navigator.clipboard.writeText(textToCopy).then(
            () => {
              alert('Copied!');
            },
            (err) => {
              console.error('Error : ', err);
            }
          );
        } else {
          alert('Copy to clipboard is not available in your browser.');
        }
    };

    return (
        <div className="py-10">
            <div className="container text-sm mx-auto px-2">
                <div>
                  Made with <span className="animate-pulse">♥</span>
                </div> 
                <div className="truncate">
                  <span className="cursor-copy hover:underline" onClick={() =>  copyToClipboard("AU1L3YbT7SBwxdVwzacoonEgou5oXi5mNfXMaXqYhoL69GVrDUrE")}>AU1L3YbT7SBwxdVwzacoonEgou5oXi5mNfXMaXqYhoL69GVrDUrE</span>
                </div>
            </div>
        </div>
    );
}
        
export default Footer;