import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../components/header'
import Footer from '../components/footer'
import SearchBar from '../components/searchbar'
import { TransfertData } from '../interfaces'
import { formatTimeAgo } from '../functions'
import { explorer } from '../constantes'

function Operation() {
  const { operation } = useParams<{ operation: string }>();
  const [operationData, setoperationData] = useState<TransfertData | null>({} as TransfertData);
  const [error, setError] = useState<string | null>(null);
  
  const fetchOperationData = async () => {
    try {
      const responseOperationData = await fetch('https://api.massa.ga/operation/' + operation);
      if (!responseOperationData.ok) {
        throw new Error('Error retrieving data..');
      }
      const resultTransferts: TransfertData = await responseOperationData.json();
      setoperationData(resultTransferts);
      setError(null);
    } catch (error) {
      setError('Error retrieving data..');
      setoperationData(null);
    }
  };

  useEffect(() => {
    fetchOperationData();
  }, []);

  return (
    <div>
      <Header />
      <div className="container mx-auto mt-32 px-2">
        <div className="relative overflow-x-auto">
            <SearchBar />
            {operationData?
              <div>
                <div className="py-2 truncate"><span className="text-sm">Operation</span> <span className="font-bold">{operationData["ID_OP"]}</span></div>
                <div className="rounded-md text-sm text-slate-700 bg-slate-200 px-2 font-bold py-5">Details</div>
                <div className="grid grid-cols-4 border border-slate-100 px-2 rounded-md mt-1">
                  <div>Operation:</div>
                  <div className="col-span-3 truncate">{operationData["ID_OP"]}</div>
                  <div>Status:</div>
                  <div className="col-span-3">
                    {operationData["STATUS"] === 1 ?
                      <span className="rounded-md bg-cyan-50 px-2 py-1 text-xs font-medium text-cyan-700 ring-1 ring-inset ring-cyan-600/20">Success</span>
                    :
                    (operationData["STATUS"] === 0 ?
                        <span className="rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20">Failed</span>
                      : 
                        <span className="rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/20">Pending</span>
                    )
                    }
                  </div>
                  <div>Age:</div>
                  <div className="col-span-3">{formatTimeAgo(operationData["TIMESTAMP"])} ago</div>
                  <div>In Block:</div>
                  <div className="col-span-3 truncate"><a href={explorer["block"] + operationData["ID_BLOCK"]}>{operationData["ID_BLOCK"]}</a></div>
                  <div className="pt-2">From:</div>
                  <div className="col-span-3 pt-2 truncate"><a href={explorer["address"] + operationData["FROM_ADDR"]}>{operationData["FROM_ADDR"]}</a></div>
                  <div>To:</div>
                  <div className="col-span-3 truncate"><a href={explorer["address"] + operationData["TO_ADDR"]}>{operationData["TO_ADDR"]}</a></div>
                  <div className="pt-2">Transfered Token:</div>
                  <div className="col-span-3 pt-2 truncate content-end">
                    {!operationData.SYMBOL ?
                      <div>NFT #{operationData.AMOUNT}</div>
                    :
                      <div>{(parseInt(operationData["AMOUNT"])/Math.pow(10, operationData["DECIMALS"])).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: operationData["DECIMALS"] })} <a href={explorer["token"] + operationData["CONTRACT"]}>{operationData["SYMBOL"]} <span className="text-sm italic">({operationData["NAME"]})</span></a></div>
                    }
                  </div>
                  {operationData["CONTRACT"] != "0" && <div>Transfered Coins:</div>}
                  {operationData["CONTRACT"] != "0" && <div className="col-span-3 content-end">{operationData["TRANSFERED_COINS"]} MAS</div>}
                  <div>Fees:</div>
                  <div className="col-span-3">{operationData["FEES"]} MAS</div>
                </div>
              </div>
            :
            <div className="text-left text-red-500">{error ? error : "Operation not valid!"}</div>
            }
          </div>
      </div>
      <Footer />
    </div>
  );
}

export default Operation;