import React, { useState, ChangeEvent, KeyboardEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { explorer } from '../constantes'

function SearchBar() {
    const [searchInput, setSearchInput] = useState<string>('');
    const navigate = useNavigate();

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.trim();
        setSearchInput(value);
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            search();
        }
    };

    function search() {
        if(searchInput.length > 3)
        {
            switch (searchInput[0]) {
                case "B":
                    console.log("BLOCK");
                    window.location.href = explorer["block"] + searchInput;
                    break;
            
                case "O":
                    console.log("OPERATION");
                    window.location.href = explorer["operation"] + searchInput;
                    break;
                    
                case "A":
                    if(searchInput[1] === "S") {
                        console.log("TOKEN");
                        window.location.href = explorer["token"] + searchInput;
                    }
                    else {
                        console.log("ADDRESS");
                        window.location.href = explorer["address"] + searchInput;
                    }
                    break;
            }
        }
    }

    return (
        <div className="pb-4 pt-5 bg-white">
            <label htmlFor="table-search" className="sr-only">Search</label>
            <div className="flex py-1">
                <input onChange={handleInputChange} onKeyDown={handleKeyDown} type="text" id="search" className="p-2 text-sm text-gray-900 border border-gray-300 rounded-lg w-5/6 bg-gray-50 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-cyan-300 mx-1" placeholder="Block / Operation / Address" />
                <button onClick={search} type="button" className="text-slate-100 bg-gradient-to-r from-cyan-400 via-cyan-500 to-cyan-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-cyan-300 shadow-md shadow-cyan-500/50 font-medium rounded-lg text-sm p-2 text-center mx-1 w-1/6">
                    <svg className="w-4 h-4 text-slate-100 mx-auto md:hidden" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                    </svg>
                    <span className="hidden md:block">Search</span>
                </button>
            </div>
        </div>
    );
}
        
export default SearchBar;
