import React, { useEffect, useState } from 'react';
import Header from '../components/header'
import Footer from '../components/footer'
import SearchBar from '../components/searchbar'
import ListTranferts from '../components/listTransferts'
import { TransfertData } from '../interfaces'

function Home() {
  const [last_transferts, setLastTransferts] = useState<TransfertData[]>([]);
  const [error, setError] = useState<string | null>(null);

  const fetchLastTransferts = async () => {
    try {
      const responseTransferts = await fetch('https://api.massa.ga/last_transferts');
      if (!responseTransferts.ok) {
        throw new Error('Error retrieving data..');
      }
      const resultTransferts: TransfertData[] = await responseTransferts.json();
      setLastTransferts(resultTransferts);
      setError(null);
    } catch (error) {
      setError('Error retrieving data..');
      setLastTransferts([]);
    }
  };

  useEffect(() => {
    fetchLastTransferts();

    const interval = setInterval(() => {
      fetchLastTransferts();
    }, 5000);

    return () => clearInterval(interval);
  }, []);
  
  return (
    <div>
      <Header />
      <div className="container mx-auto mt-32 px-2">
        <div className="relative overflow-x-auto">
            <SearchBar />
            <div className="py-2"><span className="text-sm">Last</span> <span className="font-bold">20</span> <span className="text-sm">token transferts</span></div>
            <ListTranferts transferts={last_transferts} nbPages={0} error={error}/>
        </div>
      </div>
      <Footer />
    </div>
  );  
}

export default Home;