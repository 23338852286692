import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NotFound from './pages/NotFound';
import Home from './pages/Home';
import Token from './pages/Token';
import Address from './pages/Address';
import Operation from './pages/Operation';
import Block from './pages/Block';
import './App.css';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/token/:token" element={<Token />} />
        <Route path="/token/:token/:page" element={<Token />} />
        <Route path="/address/:address" element={<Address />} />
        <Route path="/address/:address/:page" element={<Address />} />
        <Route path="/operation/:operation" element={<Operation />} />
        <Route path="/block/:block" element={<Block />} />
        <Route path="/block/:block/:page" element={<Block />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;