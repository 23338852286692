import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../components/header'
import Footer from '../components/footer'
import SearchBar from '../components/searchbar'
import ListTranferts from '../components/listTransferts'
import { TransfertData } from '../interfaces'

function Token() {
  const { token } = useParams<{ token: string }>();
  const { page } = useParams<{ page: string }>();
  const [transferts, setTransferts] = useState<TransfertData[]>([]);
  const [nbPages, setnbPages] = useState<number>();
  const [error, setError] = useState<string | null>(null);
  
  const fetchTransferts = async () => {
    try {
      let selectedPage = 0;
      if(page) {
        selectedPage = parseInt(page)-1;
        if(selectedPage < 0) selectedPage = 0;
      }
      const responseTransferts = await fetch('https://api.massa.ga/token/' + token + "/" + selectedPage);
      if (!responseTransferts.ok) {
        throw new Error('Error retrieving data..');
      }
      const resultTransferts: TransfertData[] = await responseTransferts.json();
      setTransferts(resultTransferts);
      setError(null);
    } catch (error) {
      setError('Error retrieving data..');
      setTransferts([]);
    }
  };

  const fetchNbPages = async () => {
    try {
      const responseNbPages = await fetch('https://api.massa.ga/token_count/' + token);
      if (!responseNbPages.ok) {
        throw new Error('Error retrieving data..');
      }
      const resulNbPages = await responseNbPages.json();
      setnbPages(resulNbPages.NB_PAGES);
      setError(null);
    } catch (error) {
      setError('Error retrieving data..');
      setnbPages(0);
    }
  };

  useEffect(() => {
    fetchTransferts();
    fetchNbPages();

    const interval = setInterval(() => {
      fetchTransferts();
      fetchNbPages();
    }, 15000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <Header />
      <div className="container mx-auto mt-32 px-2">
        <div className="relative overflow-x-auto">
            <SearchBar />
            <div className="py-2 truncate"><span className="text-sm">Token</span> <span className="font-bold">{transferts[0] ? transferts[0]["NAME"] + " (" + transferts[0]["SYMBOL"] + ")" : token}</span></div>
            <ListTranferts transferts={transferts} nbPages={(nbPages?nbPages:0)} error={error}/>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Token;